import { NavLink } from 'react-router-dom';
import caret from '../../images/caret.svg';
import './menu.css';

const Menu = ({menuClass}) => {
    return <div className={menuClass}>
        <NavLink to={'/policy'}  className={({ isActive }) =>
                    isActive
                        ? 'd-flex w-100 py-3 px-4 my-2 active-menu-tab justify-content-between align-items-center '
                        : 'd-flex w-100 py-3 px-4 my-2 menu-tab justify-content-between align-items-center'
                }  >
            <span className='menu-text'>Privacy Policy</span>
            <img src={caret} />
        </NavLink>
        <NavLink to={'/terms'}  className={({ isActive }) =>
                    isActive
                        ? 'd-flex w-100 py-3 px-4 my-2 active-menu-tab justify-content-between align-items-center '
                        : 'd-flex w-100 py-3 px-4 my-2 menu-tab justify-content-between align-items-center'
                }>
            <span className='menu-text'>Terms of Service</span>
            <img src={caret} />
        </NavLink>
        <NavLink to={'/faqs'}  className={({ isActive }) =>
                    isActive
                        ? 'd-flex w-100 py-3 px-4 my-2 active-menu-tab justify-content-between align-items-center '
                        : 'd-flex w-100 py-3 px-4 my-2 menu-tab justify-content-between align-items-center'
                }>
            <span className='menu-text'>FAQs</span>
            <img src={caret} />
        </NavLink>
        <NavLink to={'/help'}  className={({ isActive }) =>
                    isActive
                        ? 'd-flex w-100 py-3 px-4 my-2 active-menu-tab justify-content-between align-items-center '
                        : 'd-flex w-100 py-3 px-4 my-2 menu-tab justify-content-between align-items-center'
                }>
            <span className='menu-text'>Help Center</span>
            <img src={caret} />
        </NavLink>
    </div>
};
export default Menu;