import { useState } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import './policy/policy.css';

const HelpCenter = () => {
    const [menuClass, setMenuClass] = useState('col-3 mx-4 menu-body py-4 d-none d-lg-block')
    const toggleMenu = () => {
        if (menuClass.includes('mobile-side-menu')) {
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block')
        } else (
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block mobile-side-menu')
        )
    }
    return <div>
        <Header toggleMenu={toggleMenu} />
        <div className="w-100 d-flex  justify-content-center">
            <div className="responsive-body d-flex">
                <Menu menuClass={menuClass} />
                <div className="col-10 col-lg-7 mx-4 my-4">
                    <p className="policy-title">Help Center</p>
                    <p>
                        <span className="privacy-text">We're here to assist you with any questions or concerns you may have regarding our platform and services. Below you will find helpful information and resources to guide you through your Maafipay experience. If you can't find the answers you're looking for, please don't hesitate to reach out to our customer support team for further assistance.</span>
                    </p>

                    <p className="policy-header">1. Getting Started:</p>
                    <p>
                        <span className="privacy-text">1.1 Account Creation: Learn how to create a Maafipay account and complete the registration process.</span>
                    </p>
                    <p>
                        <span className="privacy-text">1.2 Account Verification: Find step-by-step instructions on how to verify your account to access all features and increase your transaction limits.</span>
                    </p>
                    <p>
                        <span className="privacy-text">1.3 Two-Factor Authentication (2FA): Set up and manage two-factor authentication for enhanced security.</span>
                    </p>

                    <p className="policy-header">2. Using Maafipay:</p>
                    <p>
                        <span className="privacy-text">2.1 Cryptocurrency Exchange: Understand how to buy, sell, and exchange cryptocurrencies on our platform.</span>
                    </p>
                    <p>
                        <span className="privacy-text">2.2 Gift Card Trading: Learn how to buy and sell gift cards using cryptocurrencies, including tips for a smooth and secure transaction.</span>
                    </p>
                    <p>
                        <span className="privacy-text">2.2 Gift Card Trading: Learn how to buy and sell gift cards using cryptocurrencies, including tips for a smooth and secure transaction.</span>
                    </p>
                    <p>
                        <span className="privacy-text">2.3 Utility Bill Payments: Discover how to pay your utility bills using cryptocurrencies, including supported providers and the payment process.</span>
                    </p>
                    <p>
                        <span className="privacy-text">2.4 Transaction History: Access and review your transaction history to keep track of your activities on Maafipay.</span>
                    </p>

                    <p className="policy-header">3. Security and Account Protection:</p>
                    <p>
                        <span className="privacy-text">3.1 Account Security: Find best practices for securing your Maafipay account, including password management and account recovery options.</span>
                    </p>
                    <p>
                        <span className="privacy-text">3.2 Phishing and Scam Prevention: Learn how to identify and protect yourself from phishing attempts and scams targeting cryptocurrency users.</span>
                    </p>
                    <p>
                        <span className="privacy-text">3.3 Security Features: Explore the security features available on Maafipay, such as two-factor authentication and email notifications.</span>
                    </p>
                    <p className="policy-header">4. Frequently Asked Questions (FAQs):</p>
                    <p>
                        <span className="privacy-text">Browse through our comprehensive list of frequently asked questions to find answers to common inquiries about our platform, services, and security measures. </span>
                    </p>

                    <p className="policy-header">5. Contacting Customer Support:</p>
                    <p>
                        <span className="privacy-text">If you have specific questions or need personalized assistance, our dedicated customer support team is ready to help. Contact us through [Insert contact information] or use the support channel available on our platform.</span>
                    </p>
                    <p className="policy-header">6. System Requirements and Troubleshooting:</p>
                    <p>
                        <span className="privacy-text">Find information about the recommended system requirements for using Maafipay effectively and troubleshoot common technical issues.</span>
                    </p>
                    <p>
                        <span className="privacy-text">Remember, the Help Center is designed to provide general guidance and support. For account-specific or urgent matters, please contact our customer support team directly.</span>
                    </p>
                    <p>
                        <span className="privacy-text">Thank you for choosing Maafipay. We're committed to ensuring a seamless and secure experience for all our users.</span>
                    </p>



                </div>
            </div>
        </div>
        <Footer />
    </div>
};

export default HelpCenter;