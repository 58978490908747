import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import phone from '../images/boxPhone.png'
import apple from '../images/appleIcon.svg'
import playstore from '../images/googlePlayIcon.svg'
import './policy/policy.css';

const About = () => {
    return <div>
        <Header />
        <div className="w-100 d-flex justify-content-center">
            <div className="col-12 col-lg-10 mx-4 responsive-body my-4">
                <div className="about-mobile-text">
                <p className="policy-title">About Us</p>
                <p className="privacy-text">Maafipay is a leading cryptocurrency exchange and financial services provider with a team of experienced professionals who are passionate about the transformative power of digital currencies. We have a deep understanding of the crypto market and the challenges faced by both beginners and seasoned traders. Our expertise and commitment enable us to deliver exceptional services tailored to the unique needs of our customers.</p>
                <p className="privacy-text">At Maafipay, we are dedicated to providing secure, efficient, and reliable services in cryptocurrency exchange, gift card trading, and utility bill payments using cryptocurrencies.</p>
                </div>
               <div className="d-flex flex-column flex-lg-row justify-content-between about-container">
                    <div className="d-flex flex-column w-50 w-lg-50 justify-content-center about-download-holder m-4">
                        <p className="about-downoad-text">Download the App to <br className="d-none d-lg-block"/>
                            Get Started</p>
                        <div className='d-flex flex-column flex-lg-row align-items-center'>
                            <div className='d-flex about-mobile-link px-3 py-2  w-100 align-items-center about-mobile-holder'>
                                <img src={apple} />
                                <div className='ms-3'>
                                    <p className='mobile-link-title'>Download on the</p>
                                    <p className='mobile-link-text'>App Store</p>
                                </div>
                            </div>
                            <div className='d-flex px-3 py-2 about-mobile-link align-items-center w-100  about-mobile-holder'>
                                <img src={playstore} />
                                <div className='ms-3'>
                                    <p className='mobile-link-title'>GET IT ON</p>
                                    <p className='mobile-link-text'>App Store</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <img className="about-phone" src={phone} />
                </div>
            </div>
        </div>
        <Footer />
    </div>
};

export default About;