import { useState } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import './policy/policy.css';

const FAQs = () => {
    const [menuClass, setMenuClass] = useState('col-3 mx-4 menu-body py-4 d-none d-lg-block')
    const toggleMenu = () => {
        if (menuClass.includes('mobile-side-menu')) {
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block')
        } else (
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block mobile-side-menu')
        )
    }
    return <div>
        <Header toggleMenu={toggleMenu} />
        <div className="w-100 d-flex justify-content-center ">
            <div className="responsive-body d-flex">
                <Menu menuClass={menuClass} />
                <div className="col-10 col-lg-7 mx-4 my-4">
                    <p className="policy-title">Frequently Asked Questions</p>
                    <p className="policy-header">1. What is Maafipay?</p>
                    <p>
                        <span className="privacy-text">Maafipay is a cryptocurrency exchange and financial services platform that enables users to buy, sell, and exchange cryptocurrencies, trade gift cards, and pay utility bills using cryptocurrencies. Our goal is to provide a secure, user-friendly platform that empowers individuals and businesses to embrace the potential of digital currencies.
                        </span>
                    </p>
                    <p className="policy-header">2. How do I create an account on Maafipay?</p>
                    <p>
                        <span className="privacy-text">To create an account on Maafipay, simply visit our website and click on the "Sign Up" or "Register" button. Follow the prompts to provide the required information, including your name, email address, and password. Once you've completed the registration process, you'll be able to access and use our services.
                        </span>
                    </p>
                    <p className="policy-header">3. How do I verify my account on Maafipay?</p>
                    <p>
                        <span className="privacy-text">Account verification is a crucial step to unlock all features and increase your transaction limits on Maafipay. To verify your account, log in and navigate to the account settings or verification section. Follow the instructions to provide the necessary documentation, such as a government-issued ID or proof of address. Our verification team will review your submission, and once approved, you'll receive a confirmation.
                        </span>
                    </p>
                    <p className="policy-header">4. How can I buy cryptocurrencies on Maafipay?</p>
                    <p>
                        <span className="privacy-text">To buy cryptocurrencies on Maafipay, ensure that you have a verified account and sufficient funds in your account balance. Log in to your account and navigate to the "Buy" or "Exchange" section. Select the desired cryptocurrency and specify the amount you wish to purchase. Follow the prompts to complete the transaction, and the purchased cryptocurrencies will be added to your account balance.
                        </span>
                    </p>
                    <p className="policy-header">5. Can I sell my cryptocurrencies on Maafipay?</p>
                    <p>
                        <span className="privacy-text">Yes, you can sell your cryptocurrencies on Maafipay. Log in to your account and navigate to the "Sell" or "Exchange" section. Choose the cryptocurrency you want to sell and enter the amount you wish to sell. Follow the instructions to complete the transaction. The corresponding funds will be credited to your account balance. </span>
                    </p>

                    <p className="policy-header">6. What gift cards can I trade on Maafipay?</p>
                    <p>
                        <span className="privacy-text">Maafipay supports a wide range of popular gift cards for trading. You can trade gift cards from various retailers, including but not limited to Amazon, Walmart, iTunes, Google Play, and more. Check our platform for the current list of supported gift cards.</span>
                    </p>
                    <p className="policy-header">7. How do I pay utility bills using cryptocurrencies on Maafipay?</p>
                    <p>
                        <span className="privacy-text">To pay utility bills using cryptocurrencies on Maafipay, ensure that you have sufficient funds in your account balance. Log in to your account and navigate to the "Bill Payments" section. Select the utility provider and enter the necessary details, such as the bill amount and account number. Follow the instructions to complete the payment process.</span>
                    </p>
                    <p className="policy-header">8. Is my personal information secure on Maafipay?</p>
                    <p>
                        <span className="privacy-text">We take the security and privacy of your personal information seriously. Maafipay implements industry-standard security measures to protect your data from unauthorized access, loss, or theft. We also comply with applicable data protection regulations. For more details, please refer to our Privacy Policy.</span>
                    </p>
                    <p className="policy-header">9. How can I contact customer support?</p>
                    <p>
                        <span className="privacy-text">If you have any questions, concerns, or need assistance, our customer support team is here to help. You can reach us through [Insert contact information]. Alternatively, you can use the support channel available on our platform to submit your inquiries or requests.</span>
                    </p>
                    <p className="policy-header">10. Are there any fees associated with using Maafipay?</p>
                    <p>
                        <span className="privacy-text">Maafipay applies fees for certain transactions and services, such as cryptocurrency trading, gift card trading, and bill payments. These fees may vary based on the specific transaction type and the prevailing market conditions. Please refer to our fee schedule or contact customer support for detailed information regarding the fees applicable to your transactions.</span>
                    </p>
                    <p>
                        <span className="policy-sub-header">Note: </span>
                        <span className="privacy-text">The FAQs provided above are general and for informational purposes only. For more specific information or inquiries, please</span>
                    </p>


                </div>
            </div>
        </div>
        <Footer />
    </div>
};

export default FAQs;