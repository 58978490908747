import { useState } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import './policy/policy.css';

const Terms = () => {
    const [menuClass, setMenuClass] = useState('col-3 mx-4 menu-body py-4 d-none d-lg-block')
    const toggleMenu = () => {
        if (menuClass.includes('mobile-side-menu')) {
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block')
        } else (
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block mobile-side-menu')
        )
    }
    return <div>
        <Header toggleMenu={toggleMenu} />
        <div className="w-100 d-flex justify-content-center">
            <div className="responsive-body d-flex">
                <Menu menuClass={menuClass} />
                <div className="col-10 col-lg-7 mx-4 my-4">
                    <p className="policy-title">Terms of Service</p>
                    <p className="privacy-text">Please read these Terms of Service ("Terms") carefully before using the Maafipay website or any of our services. These Terms govern your access to and use of the Maafipay platform. By using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our platform.</p>
                    <p className="policy-header">1. Account Registration:</p>
                    <p>
                        <span className="policy-sub-header">1.1 Eligibility: </span>
                        <span className="privacy-text">By creating an account with Maafipay, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into a binding agreement. If you are accessing our services on behalf of a company or organization, you further represent and warrant that you have the authority to bind that entity to these Terms.
                        </span>
                    </p>
                    <p>
                        <span className="policy-sub-header">1.2 Account Information: </span>
                        <span className="privacy-text">You are responsible for providing accurate and up-to-date information during the account registration process. You agree to keep your account information confidential and not share it with any third parties. You are solely responsible for any activities that occur under your account.
                        </span>
                    </p>
                    <p className="policy-header">2. Services:</p>
                    <p>
                        <span className="policy-sub-header">2.1 Cryptocurrency Exchange: </span>
                        <span className="privacy-text">Maafipay provides a platform for the exchange of cryptocurrencies. We strive to offer reliable and secure services, but we do not guarantee the availability, accuracy, or completeness of any information or services provided on our platform.
                        </span>
                    </p>
                    <p>
                        <span className="policy-sub-header">2.2 Gift Card Trading: </span>
                        <span className="privacy-text">Our platform facilitates the buying and selling of gift cards using cryptocurrencies. We act as an intermediary between users, but we do not guarantee the authenticity or value of any gift cards traded on our platform. Users are responsible for conducting their own due diligence before engaging in any gift card transactions.
                        </span>
                    </p>
                    <p>
                        <span className="policy-sub-header">2.3 Utility Bill Payments: </span>
                        <span className="privacy-text">Maafipay enables users to pay their utility bills using cryptocurrencies. While we strive to process payments accurately and in a timely manner, we cannot be held responsible for any delays, errors, or issues that may arise from the utility providers' systems or processes
                        </span>
                    </p>
                    <p className="policy-header">3. User Conduct:</p>
                    <p>
                        <span className="policy-sub-header">3.1 Compliance: </span>
                        <span className="privacy-text">You agree to comply with all applicable laws, regulations, and third-party agreements while using our platform. You are solely responsible for ensuring that your use of Maafipay's services is lawful in your jurisdiction.
                        </span>
                    </p>
                    <p>
                        <span className="policy-sub-header">3.2 Prohibited Activities: </span>
                        <span className="privacy-text">You agree not to engage in any activities that may:
                        </span>
                    </p>
                    <p>
                        <span className="privacy-text">a. Violate any laws or regulations;</span><br />
                        <span className="privacy-text">b. Infringe upon the rights of others;</span><br />
                        <span className="privacy-text">c. Interfere with or disrupt the functionality of our platform;</span><br />
                        <span className="privacy-text">d. Engage in fraudulent or deceptive practices;</span><br />
                        <span className="privacy-text">e. Generate or transmit harmful code, viruses, or other malicious software;</span><br />
                        <span className="privacy-text">f. Attempt to gain unauthorized access to our systems or user accounts;</span><br />
                        <span className="privacy-text">g. Engage in any activity that may damage, disable, or impair the integrity or performance of our platform.</span><br />
                    </p>
                    <p className="policy-header">4. Intellectual Property:</p>
                    <p>
                        <span className="policy-sub-header">4.1 Ownership: </span>
                        <span className="privacy-text">Maafipay and its licensors retain all rights, title, and interest in and to the platform, including any trademarks, logos, or copyrights associated with Maafipay. You may not use our intellectual property without our prior written consent.
                        </span>
                    </p>
                    <p>
                        <span className="policy-sub-header">4.2 User Content: </span>
                        <span className="privacy-text">By using our platform, you grant Maafipay a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display any content you provide through our platform. You represent and warrant that you have the necessary rights to grant us this license.
                        </span>
                    </p>
                    <p className="policy-header">5. Limitation of Liability:</p>
                    <p>
                        <span className="privacy-text">To the maximum extent permitted by applicable law, Maafipay and its affiliates, directors, employees, or agents shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our platform, including but not limited to any losses or damages resulting from:
                        </span>
                    </p>
                    <p>
                        <span className="privacy-text">- Use or inability to use our services
                        </span><br />
                        <span className="privacy-text">- Unauthorized access to or alteration of your transmissions or data
                        </span><br />
                        <span className="privacy-text">- Statements or conduct of any third party on our platform
                        </span><br />
                        <span className="privacy-text">- Any other matter relating to our platform or services
                        </span><br />
                    </p>
                    <p className="policy-header">6. Indemnification:</p>
                    <p>
                        <span className="privacy-text">You agree to indemnify and hold Maafipay and its affiliates, directors, employees, or agents harmless from any claims, demands, liabilities, damages, losses, or expenses, including reasonable attorney's fees, arising out of or in connection with your use of our platform or any violation of these Terms.
                        </span>
                    </p>
                    <p className="policy-header">7. Governing Law and Dispute Resolution:</p>
                    <p>
                        <span className="privacy-text">These Terms shall be governed by and construed in accordance with the laws of the country. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts in the country's jurisdiction.
                        </span>
                    </p>
                    <p className="policy-header">8. Amendments:</p>
                    <p>
                        <span className="privacy-text">Maafipay reserves the right to modify or update these Terms at any time. We will notify you of any material changes through our platform or other means. Your continued use of our services after the changes become effective constitutes your acceptance of the revised Terms.
                        </span>
                    </p>
                    <p className="policy-header">9. Contact Us:</p>
                    <p>
                        <span className="privacy-text">If you have any questions, concerns, or feedback regarding these Terms or our services, please contact us at info@maafipay.com.
                        </span>
                    </p>
                    <p>
                        <span className="privacy-text">By using Maafipay's services, you acknowledge that you have read, understood, and agreed to these Terms of Service.
                        </span>
                    </p>

                </div>
            </div>
        </div>
        <Footer />
    </div>
};

export default Terms;