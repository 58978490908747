import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Menu from "../../components/menu/Menu";
import './policy.css';

const Policy = () => {
    const [menuClass, setMenuClass] = useState('col-3 mx-4 menu-body py-4 d-none d-lg-block')
    const toggleMenu = () => {
        if (menuClass.includes('mobile-side-menu')) {
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block')
        } else (
            setMenuClass('col-3 mx-4 menu-body py-4 d-none d-lg-block mobile-side-menu')
        )
    }
    return <div>
        <Header toggleMenu={toggleMenu} />
        <div className="w-100 d-flex justify-content-center ">
            <div className="responsive-body d-flex">

                <Menu menuClass={menuClass} />
                <div className="col-10 col-lg-7 mx-4 my-4">
                    <p className="policy-title">Privacy Policy</p>
                    <p className="privacy-text">At Maafipay, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, store, and disclose your information when you use our website or services. By using Maafipay's services, you consent to the practices described in this Privacy Policy.</p>
                    <p className="policy-header">Information We Collect:</p>
                    <span className="policy-sub-header">1. Personal Information:</span>
                    <p className="privacy-text">When you create an account or use our services, we may collect certain personal information, such as your name, email address, contact details, and identification documents. This information is necessary for the provision of our services and compliance with applicable laws and regulations.</p>
                    <span className="policy-sub-header">2. Financial Information:</span>
                    <p className="privacy-text">To facilitate transactions and comply with anti-money laundering (AML) and know-your-customer (KYC) regulations, we may collect financial information, including bank account details, cryptocurrency wallet addresses, and transaction history. We ensure the confidentiality and security of your financial data through strict security measures.</p>
                    <span className="policy-sub-header">3. Usage Information:</span>
                    <p className="privacy-text">We collect information about how you interact with our website and services, including your IP address, browser type, operating system, device information, and usage patterns. This information helps us analyze and improve the performance, functionality, and security of our platform.</p>
                    <span className="policy-sub-header"> 4. Cookies and Tracking Technologies:</span>
                    <p className="privacy-text">We use cookies and similar tracking technologies to enhance your browsing experience, personalize content, and gather information about your usage patterns. You can manage your cookie preferences through your browser settings.</p>

                    <p className="policy-header">How We Use Your Information:</p>
                    <span className="policy-sub-header">1. Provide and Improve Services:</span>
                    <p className="privacy-text">We use your information to provide, maintain, and improve our services, including processing transactions, verifying your identity, and offering customer support. We may also use your information to personalize your experience and tailor our services to your preferences.</p>
                    <span className="policy-sub-header">2. Security and Fraud Prevention:</span>
                    <p className="privacy-text">We employ your information to ensure the security of our platform, protect against fraudulent activities, and comply with legal obligations. This includes monitoring transactions, conducting risk assessments, and implementing security measures.</p>
                    <span className="policy-sub-header">3. Communication:</span>
                    <p className="privacy-text">We may use your contact information to send you important updates, service-related announcements, and promotional offers. You can opt out of receiving marketing communications at any time by adjusting your account settings or following the instructions in the communication.</p>
                    <span className="policy-sub-header">4. Legal Compliance:</span>
                    <p className="privacy-text">We may process and disclose your information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests. This may include sharing information with regulatory authorities, law enforcement agencies, or as necessary for the prevention, detection, or investigation of illegal activities or fraud.</p>

                    <p className="policy-header">Data Retention:</p>
                    <p className="privacy-text">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We implement appropriate measures to ensure the secure disposal of your information when it is no longer needed.</p>
                    <span className="policy-sub-header">Data Security:</span>
                    <p className="privacy-text">We take data security seriously and implement industry-standard measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission or storage can be guaranteed as 100% secure. We encourage you to take steps to protect your personal information and choose strong passwords for your account.</p>
                    <span className="policy-sub-header">Third-Party Services:</span>
                    <p className="privacy-text">We may use third-party service providers to assist us in delivering our services, such as payment processors or identity verification services. These providers may have access to your information but are bound by confidentiality obligations and are only authorized to use your information as necessary to perform services on our behalf.</p>
                    <span className="policy-sub-header">Updates to Privacy Policy:</span>
                    <p className="privacy-text">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other communication channels. It is your responsibility to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.</p>

                    <p className="policy-header">Contact Us:</p>
                    <p className="privacy-text">If you have any questions, concerns</p>

                </div>
            </div>

        </div>
        <Footer />
    </div>
};

export default Policy;