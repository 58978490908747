import logo from '../../images/logo.svg';

import phoneIcon from '../../images/singlePhone.png';
import phones from '../../images/doublePhone.png';
import check from '../../images/check.svg';
import mlogo from '../../images/mobileLogo.svg';
import Typer from '../Typer';
import './home.css';
import WorldMap from "react-svg-worldmap";
import { useSpring, animated } from '@react-spring/web'
import { ComposableMap, Geographies, Geography, Marker, Annotation, ZoomableGroup } from "react-simple-maps"
import { useEffect, useState } from 'react';
import nigeria from '../../images/nigeria.svg';
import cameroon from '../../images/cameroon.svg';
import ghana from '../../images/ghana.svg';
import unitedArabEmirates from '../../images/unitedArabEmirates.svg';
import southAfrica from '../../images/southAfrica.svg';
import rwanda from '../../images/rwanda.svg';
import Footer from '../../components/footer/Footer';
import btc from '../../images/bitcoin.svg'
import eth from '../../images/ethereum.svg'
import usdt from '../../images/tether.svg'
import sell from '../../images/sellIcon.svg';
import menu from '../../images/menu.svg';
import buy from '../../images/buyIcon.svg';
import { NavLink } from 'react-router-dom';
import ChartView from '../../components/chart/Chart';
import axios from 'axios';

// curl -X 'GET' \
//   'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Ctether&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&locale=en' \
//   -H 'accept: application/json'

const Home = () => {



    const [menuclass, setMenuClass] = useState('d-none d-lg-flex');
    const [coins, setCoins] = useState(
        [
           
        ]
    )

    const getData = async () => {
        try {
            const response = await axios.get('/chart-data');
            setCoins(response.data);
        } catch (error) {
        }
    }

    useEffect(() => {
            getData();
    }, [])
    const toggleMenu = () => {
        if (menuclass.includes('mobile-menu')) {
            setMenuClass('d-none d-lg-flex')
        } else {
            setMenuClass('d-none d-lg-flex mobile-menu')

        }
    }
    const contries = [nigeria, cameroon, ghana, unitedArabEmirates, southAfrica, rwanda];

    const [state, setState] = useState({ from: 0, to: 500, top: 0, bottom: -300 })
    const springs = useSpring({
        from: { x: state.from, y: state.top },
        to: { x: state.to, y: state.bottom },
    })
    const manageAnimation = () => {
        setTimeout(() => {
            setState({
                from: 500,
                to: 0,
                top: 0,
                bottom: -300
            })
        }, 2000)
        setTimeout(() => {
            setState({
                from: 500,
                to: 0,
                top: -300,
                bottom: 0
            })
        }, 4000)
    };

    const [counter, setCounter] = useState(1);

    const changeCounter = () => {


        setTimeout(() => {
            if (counter == 1)
                return setCounter(2);
            if (counter == 2)
                return setCounter(3);
            if (counter == 3)
                return setCounter(1);
        }, 3000);
    }


    useEffect(() => {
        changeCounter();
        // manageAnimation();
    }, [counter])
    const geoUrl =
        "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/africa.json"

    return (
        <div className='general-body' >

            <div className='d-flex justify-content-center bg-dark '>

                <div className='head col-12 d-flex justify-content-center '>
                    <div className='col-12 col-lg-10 d-flex justify-content-between align-items-center p-4 position-relative '>
                        <NavLink to={'/'}>
                            <img className='d-none d-lg-block' src={logo} />
                            <img className='d-block d-lg-none' src={mlogo} />
                        </NavLink>
                        <button onClick={toggleMenu} className='btn menu-icon d-block d-lg-none'>
                            <img src={menu} />
                        </button>
                        <div className={menuclass}>
                            <NavLink to={'/faqs'}>
                                <button className='btn menu-white-text w-100'>FAQ</button>
                            </NavLink>
                            <button className='btn menu-start-btn'>Get Started</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='map-relative-holder '>
                <div className='d-flex align-items-center justify-content-center bg-dark new-map-holder'>
                    <div className='d-flex col-12 col-lg-10 '>
                        <div className='d-block d-lg-none mobile-map-bg' style={{ width: '100%', }}>
                            {/* <animated.div > */}
                            <ComposableMap projection={'geoMercator'}
                                projectionConfig={{
                                    scale: 140,
                                }}
                                width={80}
                                height={200}
                            >
                                <ZoomableGroup disablePanning zoom={1}>
                                    <Geographies geography={geoUrl} disableOptimization>
                                        {({ geographies }) =>
                                            geographies.map((geo) => (
                                                <Geography key={geo.rsmKey} geography={geo} fill="#11082A"
                                                    stroke="#21FBCC" strokeWidth={.02} stroke-dasharray="1,1.5" />
                                            ))
                                        }
                                    </Geographies>
                                </ZoomableGroup>
                            </ComposableMap>
                            {/* </animated.div> */}
                        </div>
                        <div className='d-none d-lg-block' style={{ width: '10%', }}>

                        </div>
                        <div className='d-none d-lg-block' style={{ width: '90%', }}>
                            {/* <animated.div > */}
                            <ComposableMap projection={'geoMercator'}
                                projectionConfig={{
                                    scale: 100,
                                }}
                                width={310}
                                height={310}
                            >
                                <ZoomableGroup disablePanning zoom={2}>
                                    <Geographies geography={geoUrl} disableOptimization>
                                        {({ geographies }) =>
                                            geographies.map((geo) => (
                                                <Geography key={geo.rsmKey} geography={geo} fill="#11082A"
                                                    stroke="#21FBCC" strokeWidth={.05} stroke-dasharray="1,1.5" />
                                            ))
                                        }
                                    </Geographies>
                                </ZoomableGroup>
                                {/*Nigeria*/}
                                {counter == 1 && <Marker coordinates={[10.0820, 20.6753]}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="13" height="13" rx="6.5" fill="#21FBCC" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L4.4777 6.26597L5.49502 5.09321L5.87176 4.63917C5.27773 4.30358 4.65425 4.47474 4.19497 4.97029L4.06361 5.12637C3.75909 5.47566 3.42439 5.88877 3.13964 6.26123L4.05788 7.38654C4.08451 7.42211 4.10839 7.43986 4.13663 7.47405L4.28349 7.66037C4.31528 7.70086 4.33924 7.71567 4.36772 7.75857L5.16686 8.72934C5.3769 8.98293 5.58886 9.23557 5.79866 9.48985C5.54053 9.48985 5.28241 9.48985 5.02437 9.48985C4.92157 9.48985 4.71945 9.52241 4.64763 9.4709C4.60842 9.44282 4.588 9.40139 4.55896 9.3628C4.52297 9.31482 4.48698 9.30086 4.46616 9.24031L3.61829 8.23026C3.58416 8.18926 3.57754 8.17056 3.53784 8.12896L2.61146 7.01227C2.57975 6.96998 2.57482 6.96024 2.53682 6.92036L2 6.26123L3.03515 4.99553C3.1131 4.8913 3.62733 4.26749 3.68518 4.23364C3.73811 4.10805 4.0649 3.87186 4.19771 3.78779C4.60132 3.53239 4.92633 3.49974 5.37552 3.5C5.83706 3.50043 6.13125 3.73128 6.5 3.95214V5.28719L5.651 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L8.5223 6.26597L7.50498 5.09321L7.12824 4.63917C7.72227 4.30358 8.34575 4.47474 8.80503 4.97029L8.93639 5.12637C9.24091 5.47566 9.57561 5.88877 9.86035 6.26123L8.94212 7.38654C8.91549 7.42211 8.89161 7.43986 8.86337 7.47405L8.71651 7.66037C8.68472 7.70086 8.66076 7.71567 8.63228 7.75857L7.83314 8.72934C7.6231 8.98293 7.41114 9.23557 7.20135 9.48985C7.45947 9.48985 7.71759 9.48985 7.97563 9.48985C8.07843 9.48985 8.28055 9.52241 8.35237 9.4709C8.39158 9.44282 8.412 9.40139 8.44104 9.3628C8.47703 9.31482 8.51302 9.30086 8.53383 9.24031L9.38171 8.23026C9.41584 8.18926 9.42246 8.17056 9.46216 8.12896L10.3885 7.01227C10.4203 6.96998 10.4252 6.96024 10.4632 6.92036L11 6.26123L9.96485 4.99553C9.8869 4.8913 9.37267 4.26749 9.31482 4.23364C9.26189 4.10805 8.9351 3.87186 8.80229 3.78779C8.39868 3.53239 8.07367 3.49974 7.62448 3.5C7.16294 3.50043 6.86875 3.73128 6.5 3.95214V5.28719L7.349 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                    </svg>

                                </Marker>}
                                {/*Zimabwe*/}
                                {counter == 2 && <Marker coordinates={[55.0820, -32.6753]}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="13" height="13" rx="6.5" fill="#21FBCC" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L4.4777 6.26597L5.49502 5.09321L5.87176 4.63917C5.27773 4.30358 4.65425 4.47474 4.19497 4.97029L4.06361 5.12637C3.75909 5.47566 3.42439 5.88877 3.13964 6.26123L4.05788 7.38654C4.08451 7.42211 4.10839 7.43986 4.13663 7.47405L4.28349 7.66037C4.31528 7.70086 4.33924 7.71567 4.36772 7.75857L5.16686 8.72934C5.3769 8.98293 5.58886 9.23557 5.79866 9.48985C5.54053 9.48985 5.28241 9.48985 5.02437 9.48985C4.92157 9.48985 4.71945 9.52241 4.64763 9.4709C4.60842 9.44282 4.588 9.40139 4.55896 9.3628C4.52297 9.31482 4.48698 9.30086 4.46616 9.24031L3.61829 8.23026C3.58416 8.18926 3.57754 8.17056 3.53784 8.12896L2.61146 7.01227C2.57975 6.96998 2.57482 6.96024 2.53682 6.92036L2 6.26123L3.03515 4.99553C3.1131 4.8913 3.62733 4.26749 3.68518 4.23364C3.73811 4.10805 4.0649 3.87186 4.19771 3.78779C4.60132 3.53239 4.92633 3.49974 5.37552 3.5C5.83706 3.50043 6.13125 3.73128 6.5 3.95214V5.28719L5.651 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L8.5223 6.26597L7.50498 5.09321L7.12824 4.63917C7.72227 4.30358 8.34575 4.47474 8.80503 4.97029L8.93639 5.12637C9.24091 5.47566 9.57561 5.88877 9.86035 6.26123L8.94212 7.38654C8.91549 7.42211 8.89161 7.43986 8.86337 7.47405L8.71651 7.66037C8.68472 7.70086 8.66076 7.71567 8.63228 7.75857L7.83314 8.72934C7.6231 8.98293 7.41114 9.23557 7.20135 9.48985C7.45947 9.48985 7.71759 9.48985 7.97563 9.48985C8.07843 9.48985 8.28055 9.52241 8.35237 9.4709C8.39158 9.44282 8.412 9.40139 8.44104 9.3628C8.47703 9.31482 8.51302 9.30086 8.53383 9.24031L9.38171 8.23026C9.41584 8.18926 9.42246 8.17056 9.46216 8.12896L10.3885 7.01227C10.4203 6.96998 10.4252 6.96024 10.4632 6.92036L11 6.26123L9.96485 4.99553C9.8869 4.8913 9.37267 4.26749 9.31482 4.23364C9.26189 4.10805 8.9351 3.87186 8.80229 3.78779C8.39868 3.53239 8.07367 3.49974 7.62448 3.5C7.16294 3.50043 6.86875 3.73128 6.5 3.95214V5.28719L7.349 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                    </svg>

                                </Marker>}
                                {/*South Africa*/}
                                {counter == 3 && <Marker coordinates={[32.0820, -40.6753]}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="13" height="13" rx="6.5" fill="#21FBCC" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L4.4777 6.26597L5.49502 5.09321L5.87176 4.63917C5.27773 4.30358 4.65425 4.47474 4.19497 4.97029L4.06361 5.12637C3.75909 5.47566 3.42439 5.88877 3.13964 6.26123L4.05788 7.38654C4.08451 7.42211 4.10839 7.43986 4.13663 7.47405L4.28349 7.66037C4.31528 7.70086 4.33924 7.71567 4.36772 7.75857L5.16686 8.72934C5.3769 8.98293 5.58886 9.23557 5.79866 9.48985C5.54053 9.48985 5.28241 9.48985 5.02437 9.48985C4.92157 9.48985 4.71945 9.52241 4.64763 9.4709C4.60842 9.44282 4.588 9.40139 4.55896 9.3628C4.52297 9.31482 4.48698 9.30086 4.46616 9.24031L3.61829 8.23026C3.58416 8.18926 3.57754 8.17056 3.53784 8.12896L2.61146 7.01227C2.57975 6.96998 2.57482 6.96024 2.53682 6.92036L2 6.26123L3.03515 4.99553C3.1131 4.8913 3.62733 4.26749 3.68518 4.23364C3.73811 4.10805 4.0649 3.87186 4.19771 3.78779C4.60132 3.53239 4.92633 3.49974 5.37552 3.5C5.83706 3.50043 6.13125 3.73128 6.5 3.95214V5.28719L5.651 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49992 8.5972L8.5223 6.26597L7.50498 5.09321L7.12824 4.63917C7.72227 4.30358 8.34575 4.47474 8.80503 4.97029L8.93639 5.12637C9.24091 5.47566 9.57561 5.88877 9.86035 6.26123L8.94212 7.38654C8.91549 7.42211 8.89161 7.43986 8.86337 7.47405L8.71651 7.66037C8.68472 7.70086 8.66076 7.71567 8.63228 7.75857L7.83314 8.72934C7.6231 8.98293 7.41114 9.23557 7.20135 9.48985C7.45947 9.48985 7.71759 9.48985 7.97563 9.48985C8.07843 9.48985 8.28055 9.52241 8.35237 9.4709C8.39158 9.44282 8.412 9.40139 8.44104 9.3628C8.47703 9.31482 8.51302 9.30086 8.53383 9.24031L9.38171 8.23026C9.41584 8.18926 9.42246 8.17056 9.46216 8.12896L10.3885 7.01227C10.4203 6.96998 10.4252 6.96024 10.4632 6.92036L11 6.26123L9.96485 4.99553C9.8869 4.8913 9.37267 4.26749 9.31482 4.23364C9.26189 4.10805 8.9351 3.87186 8.80229 3.78779C8.39868 3.53239 8.07367 3.49974 7.62448 3.5C7.16294 3.50043 6.86875 3.73128 6.5 3.95214V5.28719L7.349 6.26597L6.5 7.24476L6.49992 8.5972Z" fill="#6F0FAB" />
                                    </svg>

                                </Marker>}
                                {counter == 1 && <Annotation
                                    subject={[17.0820, 20.6753]}
                                    dx={7}
                                    dy={-10}
                                    connectorProps={{
                                        stroke: "#21FBCC",
                                        strokeWidth: .2,
                                        strokeLinecap: "round"
                                    }}
                                >
                                    <text style={{ fontSize: 5 }} x="-5" textAnchor="start" alignmentBaseline="middle" fill="#fff">
                                        {"Nigeria"}
                                    </text>
                                </Annotation>}
                                {counter == 2 && <Annotation
                                    subject={[58.5820, -31.6753]}
                                    dx={0}
                                    dy={-10}
                                    connectorProps={{
                                        stroke: "#21FBCC",
                                        strokeWidth: .2,
                                        strokeLinecap: "round"
                                    }}
                                >
                                    <text x="-8" style={{ fontSize: 5 }} y="-1" textAnchor="start" alignmentBaseline="middle" fill="#fff">
                                        {"Zimbabwe"}
                                    </text>
                                </Annotation>}
                                {counter == 3 && <Annotation
                                    subject={[24.0820, -43.6753]}
                                    dx={12}
                                    dy={0}
                                    connectorProps={{
                                        stroke: "#21FBCC",
                                        strokeWidth: .2,
                                        strokeLinecap: "round"
                                    }}
                                >
                                    <text x="-12" style={{ fontSize: 5 }} textAnchor="end" alignmentBaseline="middle" fill="#fff">
                                        {"South Africa"}
                                    </text>
                                </Annotation>}
                            </ComposableMap>
                            {/* </animated.div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-100 d-flex align-items-center justify-content-center'>
                <div className='header col-12 col-lg-9 responsive-body '>
                    <div className='mobile-header'>
                        <div className='header-title-container'>

                            <p className='header-title'>Trade your   <Typer heading="Things for hipsters:"
                                dataText={[
                                    'Crypto',
                                    'Gift Card',
                                ]} /><br /> <span className='header-title'>On MaafiPay</span></p>
                            <p className='header-small-text w-75 '>Multipurpose use of cryptocurrency is better, easier
                                and faster on <span className='green-text'>Maafipay</span>, what are you waiting for?</p>
                            <div className='d-flex flex-column flex-lg-row mt-4 mt-lg-0 align-items-center w-75'>
                                <button className='btn header-container-start-btn px-2 w-50 py-2'>Get Started</button>
                                <button className='btn d-none d-lg-inline py-2 header-container-app-btn w-50'>Get App</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex first-phone-holder justify-content-center bg-light'>
                <div className='d-flex flex-column-reverse col-12 col-lg-9  responsive-body flex-lg-row align-items-center justify-content-between second-container '>
                    <div className='second-text-holder'>


                        <p className='maafi-intro'><span className='purple-text'>Maafipay</span> is designed to meet your day-to-day trade and purchase needs, it
                            Is fast, safe and reliable</p>
                        <p className='tiny-text'>Sign up to buy crypto at the best market rates. Buy, spend, swap, and store your favourite crypto assets all in one highly secured app. Buy
                            crypto with a debit card, credit card or Apple Pay.</p>
                        <button className='btn learn-more px-4 py-2'>
                            Learn more
                        </button>
                    </div>
                    <img className='first-phone' src={phoneIcon} />
                </div>
            </div>
            {/* <ChartView /> */}

            <div>
                <div className='green-row pb-4'>
                    <p className='green-row  chart-title pt-4 px-2 text-center col-5 '>Buy and sell crypto instantly at the best prices</p>

                </div>
            </div>
            <div className='green-row d-flex flex-column align-items-center px-0 px-lg-4 pb-4 ' >
                <div className='col-12 col-lg-9  responsive-body table-responsive'>
                    <table class="table table-sm   col-lg-9">
                        <thead className="chart-table-header p-4 ">
                            <tr className='px-4'>
                                <th className='table-title' ><span className='ms-2 ms-lg-0'>Name</span></th>
                                <th className='d-none d-lg-table-cell'></th>
                                <th className='table-title' >Price</th>
                                <th className='table-title'>Change</th>
                                <th className='table-title'>Chart</th>
                                <th className='table-title'>Trade</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {coins.map((coin) => <tr className='table-row-container'>
                                <td >
                                    <div className='d-flex align-items-center'>
                                        <img className='ms-2 ms-lg-0 chart-coin-icon' src={coin.iconUrl} />
                                        <span className='coin-name  d-none d-lg-inline'>{coin.name}</span>
                                        <span className='coin-name d-inline d-lg-none '>{coin.symbol.toUpperCase()}</span>
                                    </div>

                                </td>
                                <td className='d-none d-lg-table-cell'>   <span className='table-text  '>{coin.symbol.toUpperCase()}</span></td>
                                <td>  <span className='table-text'>${Number(coin.price).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></td>
                                <td>  <span className={coin.change.includes('-') ? 'table-sell-text' : 'table-buy-text'}>{Number(coin.change).toFixed(2)}%</span></td>
                                <td> <span className='table-image'> <ChartView color={coin.change.includes('-') ? '#C62828' : '#23A65E'} prices={coin.sparkline} /> </span></td>
                                <td> <button className='btn table-btn'>buy</button></td>
                            </tr>)}

                        </tbody>
                    </table>

                </div>

            </div>

            <div className='d-flex justify-content-center first-phone-holder bg-light'>
                <div className='d-flex flex-column-reverse flex-lg-row col-12 responsive-body col-lg-9 justify-content-between align-items-center p-4 second-container'>
                    <img className='second-phone' src={phones} />
                    <div className='what-title-holder'>
                        <p className='what-title'>What can I do on <span className='purple-text'>Maafipay</span></p>
                        <div className='d-flex align-items-center my-3'>
                            <img src={check} />
                            <span className='todo-text'>Buy and Sell Crypto coin
                            </span>
                        </div>
                        <div className='d-flex align-items-center my-3'>
                            <img src={check} />
                            <span className='todo-text'>Buy gift cards
                            </span>
                        </div>
                        <div className='d-flex align-items-center my-3'>
                            <img src={check} />
                            <span className='todo-text'>Buy recharge card using crypto

                            </span>
                        </div>
                        <div className='d-flex align-items-center my-3'>
                            <img src={check} />
                            <span className='todo-text'>Make local bill payment
                            </span>
                        </div>
                        <button className='btn learn-more px-4 py-2'>
                            Learn more
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex justify-content-center w-100'>
                <div className='d-flex flex-column responsive-body align-items-center'>
                    <div className='w-75 w-lg-100'>
                        <p className='country-title'>Maafi Pay is available in 27 countries</p>
                    </div>
                    <p className='country-sub-title'>Do you know you can get local fiat currencies for crypto when you visits
                        countries where maafipay operates without the hassle of locating an
                        exchange channel or vendor</p>
                    <div className='d-flex justify-content-center country-list over-flow-y'>
                        {contries.map((item) => <img className='mx-1 mx-lg-3 country-flag' src={item} />)}
                    </div>
                </div>
            </div> */}
            <Footer />


        </div>
    );
}

export default Home;
