import facebook from '../../images/facebookIcon.svg';
import instagram from '../../images/instagramIcon.svg';
import twitter from '../../images/twitterIcon.svg';
import apple from '../../images/appleIcon.svg';
import logo from '../../images/logo.svg';
import playstore from '../../images/googlePlayIcon.svg';
import linkedIn from '../../images/linkedInIcon.svg';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return <div className='d-flex justify-content-center bg-dark '>
        <div className='footer col-12 col-lg-10  d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-between py-4'>
            <div className='m-4 d-flex flex-column align-items-center align-items-lg-start'>
                <img src={logo} />
                <p className='footer-text mt-4'>info@maafipay.com</p>
                <div>
                    <img className='mx-2' src={facebook} />
                    <a rel="maafi instagram page" href="https://instagram.com/maafipay?igshid=MzRlODBiNWFlZA==" target="_blank">
                        <img className='mx-2' src={instagram} />
                    </a>

                    <img className='mx-2' src={twitter} />
                    <img className='mx-2' src={linkedIn} />
                </div>
            </div>
            <div className='m-4 d-flex flex-column align-items-center align-items-lg-start'>
                <NavLink
                    // onClick={(e) => e.preventDefault()}
                    className={({ isActive }) =>
                        isActive
                            ? 'dashboard-active-link head-link'
                            : 'dashboard-inactive-link head-link'
                    }
                    to={'/about'}
                    activeclassname="current"
                >
                    <p className='footer-text'>About us</p>

                </NavLink>

                <NavLink
                    // onClick={(e) => e.preventDefault()}
                    className={({ isActive }) =>
                        isActive
                            ? 'dashboard-active-link head-link'
                            : 'dashboard-inactive-link head-link'
                    }
                    to={'/help'}
                    activeclassname="current"
                >
                    <p className='footer-text'>Help</p>

                </NavLink>
                <NavLink
                    // onClick={(e) => e.preventDefault()}
                    className={({ isActive }) =>
                        isActive
                            ? 'dashboard-active-link head-link'
                            : 'dashboard-inactive-link head-link'
                    }
                    to={'/faqs'}
                    activeclassname="current"
                >
                    <p className='footer-text'>FAQs</p>

                </NavLink>
                <p className='footer-text'>Blog Post</p>
            </div>
            <div className='m-4 d-flex flex-column align-items-center align-items-lg-start'>
                <NavLink
                    // onClick={(e) => e.preventDefault()}
                    className={({ isActive }) =>
                        isActive
                            ? 'dashboard-active-link head-link'
                            : 'dashboard-inactive-link head-link'
                    }
                    to={'/policy'}
                    activeclassname="current"
                >
                    <p className='footer-text'>Privacy Policy</p>

                </NavLink>
                <NavLink
                    // onClick={(e) => e.preventDefault()}
                    className={({ isActive }) =>
                        isActive
                            ? 'dashboard-active-link head-link'
                            : 'dashboard-inactive-link head-link'
                    }
                    to={'/terms'}
                    activeclassname="current"
                >
                    <p className='footer-text'>Terms & Conditions</p>

                </NavLink>
                <p className='footer-text'>Anti-Money Laundering Policy</p>
                <div className='d-flex flex-column flex-lg-row align-items-center'>
                    <div className='d-flex  px-3 py-2  align-items-center mobile-holder'>
                        <img src={apple} />
                        <div className='mx-2'>
                            <p className='mobile-link-title'>Download on the</p>
                            <p className='mobile-link-text'>App Store</p>
                        </div>
                    </div>
                    <div className='d-flex px-3 py-2 align-items-center  mobile-holder'>
                        <img src={playstore} />
                        <div className='mx-2'>
                            <p className='mobile-link-title'>GET IT ON</p>
                            <p className='mobile-link-text'>App Store</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

};

export default Footer;