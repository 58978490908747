import { NavLink } from 'react-router-dom';
import logo from '../../images/darkLogo.svg';
import mlogo from '../../images/mobileLogo.svg';
import menu from '../../images/darkMenu.svg'
import './header.css'

const Header = ({ toggleMenu }) => {
    return <div className='w-100 general-haeder-content-holder'>
        <div className='d-flex header-nav align-items-center justify-content-center bg-light w-100 '>
        <div className='header-container d-flex col-12 col-lg-10  '>
            <div className='  w-100 d-flex align-items-center justify-content-between'>
                <NavLink to={'/'}>
                    <img className='d-none d-lg-block' src={logo} />
                    <img className='d-block d-lg-none' src={mlogo} />
                </NavLink>
                <button onClick={toggleMenu} className='btn d-block d-lg-none'>
                    <img src={menu} />
                </button>
                <div className='d-none d-lg-block'>
                    <NavLink to={'/faqs'}>
                        <button className='btn header-white-text'>FAQ</button>
                    </NavLink>
                    <button className='btn header-start-btn'>Get Started</button>
                </div>
            </div>
        </div>
    </div>
    </div>
};

export default Header;