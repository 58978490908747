import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './chart.css';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            display: false
        },
        y: {
            display: false
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};



const ChartView = ({ color, prices }) => {
    const [chartData, setChartData] = useState({
        labels:[],
        data:[],
    }) 

    const data = {
        labels:chartData.labels,
        datasets: [
            {
                label: 'Dataset 1',
                data:chartData.data,
                borderColor: color,
                backgroundColor: 'transparent',
                borderWidth: 1
            },

        ],
    };

    // const getData = async () =>{
    //  try {
    //     console.log({xxxm:coin})
    //     const response = await axios.post('/chart',{coin});

    //  } catch (error) {
    //  }
    // }

    // curl -X 'GET' \
    //   'https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=30&interval=daily' \
    //   -H 'accept: application/json'
useEffect(()=>{
    setChartData({
        labels:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
        data:prices
    })
    // getData()
},[])
    return <div className='chart-container'>
        <Line options={options} data={data} />
    </div>
};
export default ChartView;