import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../pages/About";
import FAQs from "../pages/FAQs";
import HelpCenter from "../pages/HelpCenter";
import Home from "../pages/home/Home";
import Policy from "../pages/policy/Policy";
import Terms from "../pages/TermsOfService";

const Navigator = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/help" element={<HelpCenter />} />
            <Route path="/about" element={<About />} />
        </Routes>
    </BrowserRouter>

};
export default Navigator;